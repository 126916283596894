export const navElements = [
	{
		name: "Home",
		link: "/",
	},
	{
		name: "About Us",
		link: "/about-us",
	},
	{
		name: "Procedure",
		link: "/procedure",
	},
	{
		name: "Events",
		link: "/events",
	},
];

export const mainProcessSteps = [
	{
		name: "Sea Water Filteration",
		text: "Sea water filteration involves in regular monitoring of the intake water's microbiological condition is crucial before and after the treatment process. It involves assessing the microbial quality of seawater, which is filtered to 0.5-5 micrometers and treated with 5-15ppm chlorine. The treated water is then dechlorinated, passed through a cardiac filter and an ultraviolet filter to reduce pathogen risks in the hatchery. Continuous evaluation of the water's microbiological status is conducted regularly to ensure the treatment's effectiveness.",
		link: "/filteration",
		img: "/images/HomePage/Procedure/Seawaterfilteration.png",
		video: "/images/Procedure/SeawaterFvideo.mp4",
	},
	{
		name: "Maturation process",
		text: "The maturation process of shrimp involves several stages of growth and development from nauplius to postlarvae stage. The process takes several months, and it involves the development of complex structures and organs, including a digestive and circulatory system. Proper nutrition, water quality, and management practices are essential for successful shrimp maturation.",
		img: "/images/HomePage/Procedure/maturationprocess.png",
		link: "/maturation-process",
		video: "/images/Procedure/maturationPVideo.mp4",
	},
	{
		name: "LRT Section",
		text: " LRT (Low salinity Rearing Technique) is a process of shrimp cultivation that involves rearing shrimp in low salinity water. This process is used to prevent disease outbreaks and improve shrimp growth. The process involves gradually decreasing the salinity of the water over several days, then maintaining the low salinity level for the duration of the cultivation period.",
		link: "/LRT",
		img: "/images/HomePage/Procedure/LRT.png",
		video: "/images/Procedure/LRTvideo.mp4",
	},
	{
		name: "Packing",
		text: " In hatcheries, shrimp are typically packaged in plastic bags filled with water and oxygen to keep them alive during transport. The bags are then placed in insulated boxes and shipped to their destination. The packaging is designed to provide a safe and secure environment for the shrimp and prevent stress or injury during transportation",
		link: "/packaging",
		img: "/images/HomePage/Procedure/Packageing.png",
		video: "/images/Procedure/packageingvideo.mp4",
	},
];

export const filterationSteps = [
	{
		name: " 01. Rabbit sand Filteration ",
		text: "This process involves in  efficiently eliminating impurities, suspended particles, and organic matter, resulting in cleaner water for shrimp seed growth. Regular backwashing is essential to maintain the filter's performance, ensuring optimal filtration and a healthy environment for shrimp development. The sand bed acts as a reliable medium to trap and remove unwanted substances, contributing to the overall water quality in the hatchery.",
		img: "images/HomePage/ProcessStep/sandfilter.png",
	},
	{
		name: "02. Slow Sand Filteration",
		text: "In the process of slow sand filtration, the water to be treated is passed through a filtration bed or tank containing a layer of sand that is several feet thick. As the water slowly moves through the sand layer, the microorganisms in the sand trap and digest impurities and pathogens. Additionally, this process is effective at removing major dust particles from the water ",
		img: "images/HomePage/ProcessStep/Tank.png",
	},
	{
		name: "03. Declorination Tanks",
		text: "A Dechlorination Tank serves to significantly lower chlorine levels in treated water, rendering them nearly undetectable. Through its chlorine removal process, the tank ensures that the discharged water meets the required quality standards. The primary objective of the Dechlorination Tank is to eliminate chlorine effectively, guaranteeing compliance with the appropriate regulations for water discharge.",
		img: "images/HomePage/ProcessStep/Declorinationtank.png",
	},
	{
		name: "04. cartridge filtration",
		text: "An overhead tank equipped with UV filtration and cartridge filtration offers advanced water purification capabilities. The tank is designed to store water and features a UV filtration system that uses ultraviolet light to disinfect the water, effectively eliminating harmful microorganisms. Additionally, the tank incorporates cartridge filtration, which further removes impurities and sediment particles through a specialized filter. This combination of UV and cartridge filtration ensures that the water stored in the overhead tank is safe, clean, and free from contaminants, providing a reliable source of potable water.",
		img: "images/HomePage/ProcessStep/overhead.png",
	},
];
export const MaturationSteps = [
	{
		name: " 01.Imported SPF Broodstock",
		text: "The Broodstock are imported from Shrimp Improvement System (SIS) in Florida, USA, and Konabay in Hawaii. These imported broodstock undergo quarantine at the Aquatic Quarantine Facility (AQF) to ensure they are specific pathogen-free (SPF), preventing the introduction of any infected individuals.The mature male and female broodstock are carefully packed in 2 to 3 polythene bags, properly oxygenated, and transported in insulated vehicles to the hatchery. Timing is crucial during transport to avoid stressful conditions for the broodstock.To protect the broodstock during transportation, a rubber tube is used to cover the rostrum of the shrimp, minimizing the risk of puncturing the plastic bags. This precautionary measure ensures the safe transport and well-being of the broodstock.",
		img: "images/HomePage/ProcessStep/brutstock.png",
	},
	{
		name: "02.Mating",
		text: "To promote successful reproduction, the mating process of shrimp is conducted under meticulous control. Male and female shrimp are carefully chosen and introduced into dedicated mating tanks. The water parameters critical for mating, such as temperature, salinity, and pH, are diligently managed to establish an ideal environment. This controlled approach guarantees the generation of robust and healthy shrimp larvae, laying the foundation for their subsequent growth and development stages.",
		img: "images/HomePage/ProcessStep/Mating.png",
	},
	{
		name: "03.Spawning and Hatching Tank",
		text: "The spawning and hatching process of shrimp in a hatchery involves carefully selected adult shrimp placed in separate tanks. Mature female shrimp release eggs, while males release sperm for fertilization. The fertilized eggs, known as zygotes, are collected and transferred to incubation tanks. In the tanks, the zygotes develop into larvae through a process called hatching. The hatched shrimp larvae are then nurtured and provided with suitable conditions for growth and development.",
		img: "images/HomePage/ProcessStep/spawning.png",
	},
	{
		name: "04.Naupllie",
		text: "Nauplii are the early larval stage of shrimp that hatch from fertilized eggs. The nauplii are typically very small and have a simple body structure with a single eye and appendages. They rely on their yolk sac for nutrition initially. The nauplii are carefully monitored and provided with suitable water conditions and nutrition to support their growth and development.",
		img: "images/HomePage/ProcessStep/nauplie.png",
	},
];
export const LRTSteps = [
	{
		name: " 01.Zoea stages",
		text: "Lorem ipsum dolor sit amet, consectetur. Earum velit sequi dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
		points: [
			{
				left: "Z1, Z2, Z3  First feeding stages",
				right: "Size range 5–30µm",
			},
			{
				left: "Pelagic filter-feeder.",
				right: "High density of feed particles Neutrally buoyant or slow-sinking.",
			},
			{
				left: "Predominantly herbivorous.",
				right: "Co-feeding of dry feeds and live algae.",
			},
			{
				left: "High energy turnover, 10–20 minutes gut passage time.",
				right: "Dry feeds are algal replacement diets.",
			},
			{
				left: "High lipase activity.",
				right: "Highly digestible feed.",
			},
		],
	},
	{
		name: " 2.Mysis stages",
		text: "Lorem ipsum dolor sit amet, consectetur. Earum velit sequi dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
		points: [
			{
				left: "M1, M2, M3.",
				right: "High density of feed particles Size range: 30–90µm.",
			},
			{
				left: "Pelagic filter-feeder.",
				right: "Neutrally buoyant or slow-sinking particles.",
			},
			{
				left: "Omnivorous / carnivorous with poor hunting behaviour.",
				right: "Co-feeding of formulated feeds and heat-killed or frozen Artemia instar I nauplii.",
			},
			{
				left: "10–20 minutes gut passage time.",
				right: "Highly digestible feed.",
			},
			{
				left: "High protease and medium lipase activity.",
				right: "Moderate lipid level: 10–15%High protein level: 50–60%",
			},
		],
	},
	{
		name: " 3.Early postlarval stages",
		text: "Lorem ipsum dolor sit amet, consectetur. Earum velit sequi dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
		points: [
			{
				left: "PL1, PL2, PL3, PL4 .",
				right: "Algal feed phased out Size range: 90–250µm.",
			},
			{
				left: "Pelagic filter-feeder.",
				right: "Neutrally buoyant or slow-sinking.",
			},
			{
				left: "Omni / carnivorous, active, hunting behavior.",
				right: "Co-feeding of Artemia instar I live nauplii and formulated feed.",
			},
			{
				left: "15–20 mins gut passage time.",
				right: "Highly digestible feed.",
			},
			{
				left: "Low enzyme activity.",
				right: "Highly digestible feed Moderate lipid level: 10–15% High protein level: 45–55%.",
			},
		],
	},
	{
		name: " 4.Postlarval stages",
		text: "Lorem ipsum dolor sit amet, consectetur. Earum velit sequi dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
		points: [
			{
				left: "PL5–PL8/15 Increasing mouth opening.",
				right: "Size range: 300–500/800µm.",
			},
			{
				left: "Benthic feeder.",
				right: "Sinking feed.",
			},
			{
				left: "Omnivorous.",
				right: "Only formulated diets.",
			},
			{
				left: "30 mins gut passage time.",
				right: "Moderately digestible feed.",
			},
			{
				left: "Increased digestive capacity.",
				right: "Low lipid level: 5–10%Moderate protein level: 40–45%.",
			},
		],
	},
];
export const packagingSteps = [
	{
		name: " 01. Filtration",
		text: "Lorem ipsum dolor sit amet, consectetur  . Earum velit sequi dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
	},
	{
		name: "02. Filtration",
		text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum   dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
	},
	{
		name: "03. Filtration",
		text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum velit  dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
	},
	{
		name: "04. Filtration",
		text: "Lorem ipsum dolor sit , consectetur  elit. Earum velit sequi dicta saepe delectus ut,	asperiores temporibus fugit ad omnis.",
		img: "images/HomePage/ProcessSteps.png",
	},
];

export const EventItems = [
	{
		img: "images/Eventspics/studentvisit.png",
		text: "Students from the College of Fisheries, Mangalore, visited Shilpa Hatcheries.",
	},
	{
		img: "images/Eventspics/nfdb.png",
		text: "A picture was taken with NFDB and National deligates on the occasion of Pradanamantri Mastya Sampada Yojana, held at Delhi.",
	},
	{
		img: "images/Eventspics/nfdbmurthy.png",
		text: "Hatchery Visit was made by Chief Executive, NFDB, Hyderabad.",
	},
	{
		img: "images/Eventspics/nfdbvisit.png",
		text: "Hatchery inspection conducted by Ms. Madhuri, Assistant Director of NFDB.",
	},
	{
		img: "images/Eventspics/nfdbdirector.png",
		text: "A picture was taken at Shilpa Hatcheries with Dr. L N Murthy, Chief Executive, NFDB, Hyderabad.",
	},
	{
		img: "images/Eventspics/f1.png",
		text: "A picture was taken at Kakinada Prawn festival with the honorable member of Parliment( Rajyasabha)Dr. B MASTAN RAO and Dr. L N Murthy, Chief Executive, NFDB, Hyderabad.",
	},
	{
		img: "images/Eventspics/f2.png",
		text: "First hatchery inspection conducted with the Assistant Director of NFDB.",
	},
	{
		img: "images/Eventspics/f3.jpg",
		text: "Award received at the Delhi Pradhanmantri Matsya Sampanna Yojana.",
	},
];
