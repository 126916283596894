import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./VideoSection.css";

const VideoSection = () => {
	return (
		<div className="videoSection">
			<img src="/images/HomePage/videoSectionBg.png" alt="" className="videoSectionBgImg" />
			<div className="videoText">
				<h3 className="videoTextHead">Providing an in-depth look into our processes and facilities.</h3>
				<button className="videoTextBtn" onClick={() => window.open("https://youtu.be/5rYPNSM4QeU")}>Watch Now</button>
			</div>
		</div>
	);
};

export default VideoSection;

// We invite you to watch our detailed video presentation,
