import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import "./aboutUs.css";
import CompanyLogo from "images/CompanyLogo";
import { Link } from "react-router-dom";
import { Facebook, Twitter, YouTube } from "@mui/icons-material";
const AboutUs = (props) => {
	const head = props.head;
	// const elements = props.elements;

	return (
		<main>
			{/* <img className="groupPhoto" src="/images/Aboutus/aboutusGroupPhoto.png" alt="" /> */}
			<>
				{" "}
				<div className="ProcedureTopBanner">
					{/* <div className="transparentblock" /> */}
					{/* <img src={pathData.img} className="ProcedureStepTopBannerRight" alt="" /> */}
					<video
						className="ProcedureTopBannerRight"
						src="/images/AboutUs/aboutusvideo.mp4"
						autoPlay
						loop
						muted></video>
					<div className="ProcedureTopBannerLeft">{/* <h2 className="ProcedureHead">Procedure</h2> */}</div>
				</div>
			</>
			<div className="aboutUs">
				<h3 className="aboutUsHead">About Us</h3>
			</div>
			<div className="aboutUs">
				<p className="aboutusP">
					&nbsp; &nbsp;Our extensive knowledge and expertise in the field of shrimp hatchery have been
					acquired through years of dedicated efforts. Our team has invested significant time in perfecting
					the art of breeding, rearing, and nurturing shrimp seedlings. This substantial experience has
					allowed us to refine our processes, implement best practices, and consistently deliver exceptional
					quality in all aspects of our hatchery operations. The longevity of our presence in the industry is
					a testament to our unwavering commitment to excellence and our ability to adapt to the evolving
					market needs. We have fostered a broad network of over 10,000 farmers, forming strong partnerships
					and actively supporting the growth of the shrimp farming community. Meeting the increasing demand
					for premium seedlings, we maintain a consistent annual production of over 300 million shrimp seeds,
					demonstrating our exceptional capacity. With an impressive hatchery production capability of over
					6,000 million nauplii per year, we ensure a steady supply of healthy and robust shrimp larvae.
				</p>
				<p className="aboutusP">
					&nbsp; &nbsp;Backed by 22 years of profound experience in the hatchery field, we bring unparalleled
					expertise and comprehensive knowledge to every aspect of our operations. Our dedicated team,
					consisting of more than 100 highly skilled employees, showcases their expertise and unwavering
					commitment to sustaining the success of our hatchery. Ensuring optimal nutrition for our shrimp
					seedlings is a priority for us. We give utmost importance to the quality of shrimp feeds and import
					approximately 2,000 kilograms per month to meet their nutritional needs. We continuously embrace
					innovation and conduct extensive research, enabling us to implement cutting-edge advancements and
					techniques in shrimp hatchery management. As responsible environmental stewards, we prioritize
					sustainable aquaculture practices and strive to minimize our ecological impact. Our hatchery
					operations are fortified by robust quality control measures, guaranteeing that our shrimp seedlings
					consistently meet the highest standards. Through our unwavering dedication to excellence, we aim to
					foster the growth and prosperity of the shrimp hatchery industry, while actively promoting economic
					development and sustainability in the communities where we operate.
				</p>
			</div>

			<div className="keypoints">
				<img className="keyPointsImg" src="/images/AboutUs/keyPointsBackground.png" alt="" />
				<div className="aboutUs keyPointsItems">
					{keyPointsItems &&
						keyPointsItems.map((row) => {
							return <KeyPointsItem data={row} />;
						})}
				</div>
			</div>

			<div className="aboutUs">
				<h2 className="chairmanhead">Chairman</h2>
				<div className="chairmanBox">
					<img src="" alt="" />
					<Stack
						className="chairmanContent"
						direction={{ sm: "column", md: "row" }}
						// justifyContent="space-around"
						spacing={4}
						pb={5}
						alignItems={{ xs: "center", md: "initial" }}
						sx={{ width: "100%" }}>
						<Box sx={{ width: { xs: "90%", md: "40%" } }}>
							<img className="chairamnPhoto" src="/images/AboutUs/ChairmanPhoto.png" alt="" />
						</Box>
						<Box sx={{ width: { xs: "90%", md: "50%" } }}>
							<p className="aboutusP">
								&nbsp; &nbsp;After successfully completing his Masters in Fisheries (MFSc),  went to pursue specialized education in fish and shrimp biology, ecology, and aquaculture techniques. His dedication to learning has resulted in a profound understanding of these subjects and mastered the art of scientific way of managing shrimp hatchery. With an impressive, dedicated career spanning 23 years in the shrimp hatchery industry, he has continually adapted to the evolving trends and advancements within the field. In 2020, took a significant step by establishing own venture, Shilpa Hatcheries LLP.
							</p>
							<p className="aboutusP">
								&nbsp; &nbsp; In addition to his technical expertise, his leadership skills are commendable. They excel in managing teams and overseeing the entire hatchery operation, ensuring efficient functioning and upholding high-quality standards. Their ability to guide and mentor colleagues showcases his effectiveness as a leader. In this journey his contributions have significantly impacted the success and growth of the hatchery they have been associated with. His comprehensive knowledge of breeding and rearing shrimp, coupled with his extensive experience, establishes as a valuable asset within the shrimp hatchery industry.
							</p>
							<Box sx={{ float: "right" }} pt={2}>
								<h4 className="chairmanName">Manja Naik</h4>
								<p className="aboutusP">Chairman, Shilpa Hatcheries LLP </p>
							</Box>
						</Box>
					</Stack>

					<Box sx={{ borderTop: "1px solid #ccc8c8 ", width: "100%", height: "10px" }}></Box>
					<Stack
						className="chairmanContent"
						direction={{ sm: "column", md: "row" }}
						// justifyContent="space-around"
						pt={5}
						spacing={4}
						alignItems={{ xs: "center", md: "initial" }}
						sx={{ width: "100%" }}>
						<Box
							sx={{
								width: { xs: "90%", md: "40%" },
								maxHeight: 450,
								overflow: "hidden",
								borderRadius: 4,
							}}>
							<img className="chairamnPhoto" src="/images/AboutUs/MP.JPG" alt="" />
						</Box>
						<Box sx={{ width: { xs: "90%", md: "50%" } }}>
							<p className="aboutusP">
								&nbsp; &nbsp;A successful Entrepreneur who has a background of Engineering Graduation in Mechanical stream and exposure in aquaculture industry especially in shrimp hatchery, gained lots of knowledge in her personal capacity and successfully ventured into multiple business which have a futuristic approach towards aqua culture industry. She has been recognised by Government of India for the contribution towards aqua culture technology with Shilpa hatchery.

							</p>
							<p className="aboutusP">
								&nbsp; &nbsp;She still has a birds eye views of everyday operations and been a guiding force from establishing to till successful operation of Shilpa Hatcheries. she has a long plan to provide best possible customer services and necessary training.

							</p>
							<Box sx={{ float: "right" }} pt={2}>
								<h4 className="chairmanName">Shilpa B Naik</h4>
								<p className="aboutusP">Managing Partner </p>
							</Box>
						</Box>
					</Stack>

					<Box pb={10}></Box>
				</div>
			</div>
			<div className="aboutUs visionMission">
				<h4 className="VMHead">Vision</h4>
				<p className="VMtext">
					Our vision is to be a leading shrimp hatchery that sets the benchmark for excellence in shrimp seed
					production, consistently delivering high-quality and robust shrimp larvae to support the thriving
					aquaculture industry.
				</p>
				<h4 className="VMHead">Mission</h4>
				<p className="VMtext">
					Our mission is to establish Unit 2 as a leading producer of freshwater prawn, specifically
					Genetically Improved {"(GI)"} scampi. We aim to excel in breeding and rearing high-quality GI
					scampi, providing farmers and the market with superior seedstock for sustainable aquaculture
					production.
				</p>
			</div>
		</main>
	);
};

export default AboutUs;

const keyPointsItems = [
	{
		name: "10,000+ farmers network.",
		img: "/images/AboutUs/network.svg",
	},
	{
		name: "300 million+ shrimp seeds production.",
		img: "/images/AboutUs/production.png",
	},
	{
		name: "6000 million+ nauplii production.",
		img: "/images/AboutUs/production.png",
	},
	{
		name: "Over 100 dedicated employees",
		img: "/images/AboutUs/employee.png",
	},
	{
		name: "Importing 2,000 kg of feed.",
		img: "/images/AboutUs/import.png",
	},
	{
		name: "22years of experience in field of hatchery.",
		img: "/images/AboutUs/experience.png",
	},
];

const KeyPointsItem = ({ data }) => {
	return (
		<div className="keyPointsItemDiv">
			<div className="keyPointsItemImgDiv">
				<img src={data.img} alt="" className="keyPointsItemDivImg" />
			</div>
			<p className="keyPointsText"> {data.name}</p>
		</div>
	);
};
