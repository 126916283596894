import { Button } from "@mui/material";
import "./herobanner.css";

const Herobanner = () => {
	return (
		<div className="heroBannerbg">
			<div className="heroBanner">
				<div className="heroLeft">
					<img src="/images/Untitled_design-removebg-preview.png" className="heroBannerLeftImg" />
					<h4 className="heroleftHead">
						Experience <br /> Premium Quality Shrimps Seeds
					</h4>
					<p className="heroleftText">
						Designed with the latest technologies to produce Premium quality Shrimp seeds, that meet the highest
						standards of sustainability and food safety.we use only the best practices, ensuring that our
						Shrimp seeds are healthy.{" "}
					</p>
					<Button variant="contained" className="heroButton"
					    onClick={() => (window.location.href = "/about-us")}>
					    
						Know More
					</Button>
				</div>
				<div className="heroRight">
					<img src="/images/HeroImage.png" className="heroBannerImg" />
				</div>
			</div>
		</div>
	);
};

export default Herobanner;
