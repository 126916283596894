import Stack from "@mui/material/Stack";
import "./ProcedureSteps.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { mainProcessSteps, filterationSteps, LRTSteps, MaturationSteps, packagingSteps } from "assets/data";
import { useEffect, useState } from "react";

const Item = ({ data, index }) => {
	return (
		<Stack alignItems="center" className="stepsItem1">
			<Box className="stepsItemLeft1">
				<img src={data.img} alt="" className="stepsItemImg1" />
			</Box>
			<Stack className="stepsItemRight" spacing={2}>
				<h4 className="processItemHead">{data.name}</h4>
				<Typography variant="subtitle1" fontSize={{ xs: 14, sm: 16 }}>
					{data.text}
				</Typography>
			</Stack>
		</Stack>
	);
};
const LRTItem = ({ data, index }) => {
	return (
		<Stack alignItems="center" className="LRTItem">
			{/* <Box className="stepsItemLeft">
				<img src={data.img} alt="" className="stepsItemImg" />
			</Box> */}
			<Stack className="stepsItemRight" spacing={2}>
				<h4 className="processItemHead">{data.name}</h4>
				{/* <Typography variant="subtitle1">{data.text}</Typography> */}
				{data.points &&
					data.points.map((row) => {
						return (
							<Box
								display="flex"
								flexWrap="wrap"
								sx={{ borderBottom: "1px solid var(--primary) " }}
								key={row.name}>
								<Typography
									variant="subtitle1"
									pb={1}
									sx={{ fontWeight: 600, width: { xs: "100%", sm: "40%" } }}>
									{row.left}
								</Typography>
								<Typography variant="subtitle1" pb={2} sx={{ width: { xs: "100%", sm: "60%" } }}>
									{row.right}
								</Typography>
							</Box>
						);
					})}
			</Stack>
		</Stack>
	);
};
const ProcessItem = ({ data, index }) => {
	return (
		<Stack justifyContent="space-around" alignItems="center" className="ProcessItem">
			<Box className="processItemLeft">
				<img src={data.img} alt="" className="ProcessItemImg" />
			</Box>
			<Stack className="processItemRight" spacing={2}>
				<h4 className="processItemHead">{data.name}</h4>
				<Typography
					variant="subtitle1"
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "-webkit-box",
						WebkitLineClamp: "3",
						WebkitBoxOrient: "vertical",
					}}>
					{data.text}
				</Typography>
				<Button
					component="a"
					onClick={() => (window.location.href = data.link)}
					sx={{
						width: "150px",
						background: "var(--primary)",
						"&:hover": { background: "var(--primary)" },
						color: "#fff",
					}}>
					Read More
				</Button>
			</Stack>
		</Stack>
	);
};

const ProcedureSteps = () => {
	const [pathData, setPathData] = useState({});
	const [steps, setSteps] = useState();

	useEffect(() => {
		const mainProcss = mainProcessSteps.filter((row) => row.link === window.location.pathname)[0];
		setPathData(mainProcss);
		if (mainProcss.link === "/filteration") setSteps(filterationSteps);
		else if (mainProcss.link === "/maturation-process") setSteps(MaturationSteps);
		else if (mainProcss.link === "/LRT") setSteps(LRTSteps);
		// else setSteps([]);
	}, []);
	return (
		<main>
			{pathData && (
				<>
					{" "}
					<div className="ProcedureStepTopBanner">
						<div className="transparentblock" />
						{/* <img src={pathData.img} className="ProcedureStepTopBannerRight" alt="" /> */}
						<video className="ProcedureStepTopBannerRight" src={pathData.video} autoPlay loop muted></video>
						<div className="ProcedureStepTopBannerLeft">
							<h4 className="ProcedureStepHead">{pathData.name}</h4>
							<p className="ProcedureStepText">{pathData.text}</p>
						</div>
					</div>
				</>
			)}
			<div className="ProcedureStep">
				{steps && <h4 className="stepsHead">Following are the steps carried out in {pathData.name} </h4>}
				<Stack className="process" direction="row" flexWrap="wrap">
					{pathData.link !== "/LRT" &&
						steps &&
						steps.map((row, index) => {
							return <Item data={row} key={row.text} index={index} />;
						})}

					{pathData.link === "/LRT" &&
						steps &&
						steps.map((row, index) => {
							return <LRTItem data={row} key={row.text} index={index} />;
						})}
				</Stack>

				<Typography variant="h4" sx={{ mt: "var(--cTopMargin)" }}>
					See other 3 steps of Procedure
				</Typography>
				<Box height={30} />
				<div className="embla">
					<div className="embla__viewport">
						<div className="embla__container">
							{mainProcessSteps &&
								mainProcessSteps.map((row) => {
									if (window.location.pathname !== row.link)
										return (
											<div className="embla__slide" key={row.id}>
												<div className="embla__slide__inner">
													<ProcessItem data={row} key={row.imgUrl} />
												</div>
											</div>
										);
									else return <div></div>;
								})}
						</div>
						<button className="embla__button embla__button--prev " type="button"></button>
						<button className="embla__button embla__button--next" type="button"></button>
					</div>
				</div>
			</div>
		</main>
	);
};

export default ProcedureSteps;
