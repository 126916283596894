import "./eventsPage.css";
import { Typography, Box, Stack } from "@mui/material";
import { EventItems } from "assets/data";

const ItemsData = [
	{
		name: "NFDB event 1",
		descripton:
			"Sit amet nulla facilisi morbi. Lacinia quis ve eros donec suscipit  tellus vel er donec ac odio tempor. ",
		img: "/images/Homepage/NFDBL.png",
		link: "",
	},
	{
		name: "NFDB Event 2",
		descripton:
			"Sit amet nulla facilisi morbi. Lacinia quis ve eros dnec suscipit  tellus vel eros donec ac odio tempor. ",
		img: "/images/Homepage/costal.png",
		link: "",
	},
];

const Item = (props) => {
	return (
		<Stack className="eventsNews_item" alignItems="center" mr={3} borderRadius={2} sx={{ background: "#FFF" }}>
			<Box
				sx={{
					width: "100%",
					overflow: "hidden",
					position: "relative",
				}}
				height={{ xs: 220, sm: 320 }}>
				<img src={props.data.img} alt="Company Img" className="eventsNews_item_img" />
			</Box>
			<Typography pt={2} pl={2} pb={4} textAlign="left" sx={{ width: "100%" }}>
				{props.data.text}
			</Typography>
		</Stack>
	);
};

const EventsPage = () => {
	return (
		<Stack direction="row" justifyContent="space-around" flexWrap="wrap" className="RecognisedSteps">
			<h3 className="eventsHead">Events</h3>

			{EventItems &&
				EventItems.map((row) => {
					return <Item data={row} key={row.text} />;
				})}
		</Stack>
	);
};

export default EventsPage;
