import axios from "axios";
import "./newsletter.css";
import { useState } from "react";
import { Stack } from "@mui/material";

const Newsletter = () => {
	const [phNo, setPhNo] = useState("");
	const [message, setMessage] = useState("");
	const handleBtnClick = (e) => {
		e.preventDefault();
		const body = {
			records: [
				{
					fields: {
						Email: phNo,
					},
				},
			],
		};
		axios
			.post("/Subscription", body)
			.then((res) => {
				setMessage("Thankyou for subscribing !!");
				setPhNo("");
			})
			.catch((err) => {
				setMessage(err.message);
				setPhNo("");
			});
	};
	return (
		<div className="newsletterMain">
			<div className="newsletterImageDiv mobileOff">
				<img src="images/HomePage/Newsletter.png" className="newsletterimg" />
			</div>
			<div className="newsletterText">
				<h3 className="newsletterHead">Subscribe to get updates</h3>
				<Stack direction="row" mt={7} component="form" onSubmit={handleBtnClick} sx={{ margin: "auto" }}>
					<input
						type="email"
						value={phNo}
						className="newsInput"
						placeholder="Enter Your Email"
						required
						onChange={(e) => setPhNo(e.target.value)}
					/>

					<button className="newsButton" type="submit">
						Subscribe
					</button>
				</Stack>
				<p>{message}</p>
			</div>
			<div className="newsletterImageDiv mobileOn">
				<img src="/images/HomePage/Newsletter.png" className="newsletterimg" />
			</div>
		</div>
	);
};

export default Newsletter;
