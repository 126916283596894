import FeaturesSection from "components/Homepage/FeaturesSection/FeaturesSection";
import Herobanner from "components/Homepage/Herobanner/Herobanner";
import ProcessSteps from "components/Homepage/ProcessSteps/ProcessSteps";
import Recognised from "components/Homepage/Recognised/Recognised";
import Newsletter from "components/Homepage/Newsletter/Newsletter";
import ChairmanMessage from "components/Homepage/ChairmanMessage/ChairmanMessage";
import EmblaCarousel from "embla-carousel";
import EventsNews from "components/Homepage/EventsNews/EventsNews";
import { useEffect } from "react";
import VideoSection from "components/Homepage/VideoSection/VideoSection";

const Home = () => {
	const setupPrevNextBtns = (prevBtn, nextBtn, embla) => {
		prevBtn.addEventListener("click", embla.scrollPrev, false);
		nextBtn.addEventListener("click", embla.scrollNext, false);
	};

	const disablePrevNextBtns = (prevBtn, nextBtn, embla) => {
		return () => {
			if (embla.canScrollPrev()) prevBtn.removeAttribute("disabled");
			else prevBtn.setAttribute("disabled", "disabled");

			if (embla.canScrollNext()) nextBtn.removeAttribute("disabled");
			else nextBtn.setAttribute("disabled", "disabled");
		};
	};

	const setupEmblaCarousel = (emblaNode, options) => {
		const viewPort = emblaNode.querySelector(".embla__viewport");
		const prevBtn = emblaNode.querySelector(".embla__button--prev");
		const nextBtn = emblaNode.querySelector(".embla__button--next");
		const embla = EmblaCarousel(viewPort, options);
		const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

		setupPrevNextBtns(prevBtn, nextBtn, embla);

		embla.on("select", disablePrevAndNextBtns);
		embla.on("init", disablePrevAndNextBtns);
	};

	const options = { dragFree: true, containScroll: "trimSnaps" };
	const emblaNodes = [].slice.call(document.getElementsByClassName("embla"));
	useEffect(() => {
		const emblaNodes = [].slice.call(document.getElementsByClassName("embla"));
		const emblaCarousels = emblaNodes.map((emblaNode) => setupEmblaCarousel(emblaNode, options));
	});

	return (
		<main>
			<Herobanner />
			<Recognised />
			<ChairmanMessage />

			<ProcessSteps />
			<VideoSection />
			<FeaturesSection />
			<EventsNews />
			<Newsletter />
		</main>
	);
};

export default Home;
