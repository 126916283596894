import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import "./footer.css";
import CompanyLogo from "images/CompanyLogo";
import { Link } from "react-router-dom";
import { Facebook, Twitter, YouTube } from "@mui/icons-material";
import PlaceIcon from "@mui/icons-material/Place";
const Footer = (props) => {
	const head = props.head;
	// const elements = props.elements;

	return (
		<footer className="footerbg">
			<Stack
				className="footer"
				direction="row"
				spacing={2}
				flexWrap="wrap"
				justifyContent={{ xs: "flex-start", sm: "space-around" }}>
				<Box className="footerLogoDiv">
					<img src="/images/companyLogoWhite.svg" alt="Shilpa Hatcheries" className="footerLogo" />
					<Stack direction="row" spacing={1} margin="auto" mt={3} pb={5}>
						<IconButton component="a" size="medium" href="">
							<Facebook fontSize="large" sx={{ color: "#fff" }} />
						</IconButton>
						<IconButton component="a" size="medium" href="">
							<Twitter fontSize="large" sx={{ color: "#fff" }} />
						</IconButton>
						<IconButton component="a" size="medium" href="">
							<YouTube fontSize="large" sx={{ color: "#fff" }} />
						</IconButton>
					</Stack>
				</Box>

				<Stack pt={2} width={{ xs: 190, sm: 150 }}>
					<Typography variant="h6" color="white" fontWeight={600}>
						Our Company
					</Typography>
					<Typography component="a" variant="subtitle1" color="white" href="/about-us">
						About Us
					</Typography>
					<Typography component="a" variant="subtitle1" color="white" href="/events">
						Events
					</Typography>
					<Typography component="a" variant="subtitle1" color="white" href="/procedure">
						Procedure
					</Typography>
				</Stack>

				{/* <Stack pt={2} width={{ xs: 130, sm: 150 }}>
					<Typography variant="h6" color="white">
						Products
					</Typography>
					<Typography variant="subtitle1" color="grey">
						Products
					</Typography>
					<Typography variant="subtitle1" color="grey">
						Products
					</Typography>
				</Stack> */}
				<Stack pt={2} width={{ xs: 190, sm: 150 }} pb={5}>
					<Typography variant="h6" color="white" fontWeight={600}>
						Quick Links
					</Typography>
					<Typography variant="subtitle1" color="white">
						Privacy policy
					</Typography>
					<Typography variant="subtitle1" color="white">
						Terms & Conditions
					</Typography>
				</Stack>
				<Stack pt={2} width={{ xs: 230, sm: 280 }}>
					<PlaceIcon sx={{ color: "white", transform: "scale(1.4)", marginLeft: "-5px", paddingBottom: 1 }} />
					<Typography variant="subtitle1" color="white" fontWeight={400}>
						Shilpa Hatcheries LLP
						Payakaraopeta,Anakapalli AndraPradesh-531127 
					</Typography>
				</Stack>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3809.2067897984757!2d82.62391407529651!3d17.305568983567706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39b79ebde30585%3A0xe20c9180a080dbac!2sShilpa%20Hatcheries%20LLP!5e0!3m2!1sen!2sin!4v1687262712543!5m2!1sen!2sin"
					className="map"></iframe>
			</Stack>
			<Typography color="white" sx={{ width: "var(--container)", margin: "auto" }} pt={2} pb={2}>
				© Shilpa Hatcheries. All rights reserved - <span>Powered by Webinfusion</span>
			</Typography>
		</footer>
	);
};

export default Footer;
