import "./chairmanMessage.css";
import { Button } from "@mui/material";

const ChairmanMessage = () => {
	return (
		<div className="chairmanMain">
			<div className="chairmanImageDiv mobileOff">
				<img src="images/HomePage/chairman.png" className="chairmanimg" alt="" />
			</div>
			<div className="chairmanText">
				<h3 className="chairmanHead">Over 22 years of industry expertise in shrimp hatchery operations</h3>
				<p className="chairmanDescription">
					Our shrimp hatchery boosts a state of the art facility purpose build for maturation, spawning-hatcheing sections and larve-post larve sections. We have sourced top-quality  SPF vanami shrimps broodstock from Florida and hawaii
					to ensure the highest standards in our hatchery operations. With meticulous control over
					environmental conditions, we create ideal settings for shrimp reproduction, emphasizing genetic
					diversity and bolstering disease resistance. Our commitment to excellence enables us to provide
					superior shrimp Seeds while prioritizing sustainable aquaculture practices.
				</p>
				<button
					variant="contained"
					className="chairmanButton"
					onClick={() => (window.location.href = "/about-us")}>
					Know More
				</button>
			</div>
			<div className="chairmanImageDiv mobileOn">
				<img src="images/HomePage/chairman.png" className="chairmanimg" />
			</div>
		</div>
	);
};

export default ChairmanMessage;
