import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./recognised.css";
import { ArrowRightAltSharp } from "@mui/icons-material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
const ItemsData = [
	{
		name: "Government of India",
		descripton:
			"The NFDB, an autonomous organization under the Department of Fisheries in the Ministry of Fisheries, Animal Husbandry and Dairying",
		img: "images/HomePage/4lion.png",
		link: "https://www.india.gov.in/",
	},
	{
		name: "National Fisheries Development Board",
		descripton:
			"The NFDB, an autonomous organization under the Department of Fisheries in the Ministry of Fisheries, Animal Husbandry and Dairying",
		img: "images/HomePage/NFDBL.png",
		link: "https://nfdb.gov.in/",
	},
	{
		name: "Costal Acquaculture Authority",
		descripton:
			"Coastal Aquaculture Authority objective is to regulate coastal aquaculture practices in a manner that promotes sustainable development while avoiding harm to the coastal environment. ",
		img: "images/HomePage/costal.png",
		link: "https://caa.gov.in/",
	},
];
const NotificationData = [
	{
		name: "Nauplii packing",
		dateday: "Daily",
		time: "6am to 9 am",
	},
	{
		name: "Seeds packing",
		dateday: "27-07-2023",
		time: "4pm- 4am",
	},
];

const Item = ({ data }) => {
	return (
		<Box className="RecognisedItem" onClick={() => window.open(data.link)}>
			<img src={data.img} alt={data.name} className="RecognisedItemImg" />
			<h6 className="RecognisedItemName">{data.name}</h6>
			{/* <Typography variant="subtitle1" className="RecognisedItemText">
				{data.descripton}
			</Typography> */}
		</Box>
	);
};
const Notification = ({ data }) => {
	return (
		<Box className="NotificationItem">
			{/* <img src={data.img} alt={data.name} style={{ width: "15%" }} /> */}
			<AutoAwesomeMotionIcon sx={{ width: "15%", transform: "scale(1.8)", color: "var(--alertColor)" }} />
			<Box pl={2} sx={{ width: "80%" }}>
				<h6 style={{ color: "var(--alertColor)", fontSize: "18px", fontWeight: 600 }}>{data.name}</h6>
				<Stack direction="row" spacing={2}>
					<Button
						sx={{ textTransform: "initial", width: "48%", color: "#var(--alertColor)" }}
						startIcon={<CalendarTodayIcon sx={{ color: "var(--alertColor)" }} />}>
						{data.dateday}
					</Button>
					<Button
						startIcon={<AccessTimeIcon sx={{ color: "var(--alertColor)" }} />}
						sx={{ textTransform: "initial", width: "48%", color: "#var(--alertColor)" }}>
						{data.time}
					</Button>
				</Stack>
			</Box>
		</Box>
	);
};

const RecognisedSteps = () => {
	return (
		<Stack direction="row" justifyContent="space-around" flexWrap="wrap" className="RecognisedSteps">
			<Stack
				spacing={2}
				className="recognizedLeft"
				alignItems="center"
				sx={{ width: { xs: "90%", md: "50%" } }}
				pb={2}>
				<h3 className="RecognisedHead">Recognition that motivates us to improve ourselves.</h3>

				{ItemsData &&
					ItemsData.map((row) => {
						return <Item data={row} key={row.descripton} />;
					})}
			</Stack>
			<Stack spacing={2} className="recognizedRight" pb={2}>
				<Stack direction="row" flexWrap="wrap" alignItems="center" pb={2}>
					<Box sx={{ width: { xs: "20%", md: "28%" } }}>
						<img src="/images/HomePage/announcement.png" alt="" style={{ width: "100%" }} />
					</Box>

					<Box sx={{ width: { xs: "70%", md: "65%" } }} pb={2} pl={2}>
						<h3 className="alertHead">What&apos;s New</h3>
						<p style={{ color: "white" }}>
							Here we will update with new products and Events. So stay updated
						</p>
					</Box>
				</Stack>

				{NotificationData &&
					NotificationData.map((row) => {
						return <Notification data={row} key={row.descripton} />;
					})}
			</Stack>
		</Stack>
	);
};

export default RecognisedSteps;
