import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./processSteps.css";
import { ArrowRightAltSharp } from "@mui/icons-material";
import { mainProcessSteps } from "assets/data";

const ItemsData = [
	{
		name: "Sea Water Filteration",
		text: "Sea water filtration involves a series of stages aimed at eliminating impurities, contaminants, and particles present in seawater to make it appropriate and secure for different uses, including drinking, irrigation, and industrial processes. Each stage in the process is essential in guaranteeing the efficiency of the filtration system.",
		img: "/images/Homepage/Procedure/Seawaterfilteration.png",
		link: "",
	},
	{
		name: "Mansturation process",
		text: "The maturation process of shrimp involves several stages of growth and development from nauplius to postlarvae stage. The process takes several months, and it involves the development of complex structures and organs, including a digestive and circulatory system. Proper nutrition, water quality, and management practices are essential for successful shrimp maturation and aquaculture.",
		img: "/images/Homepage/Procedure/maturation.png",
		link: "",
	},
	{
		name: "LRT Process",
		descripton:
			" LRT (Low salinity Rearing Technique) is a process of shrimp cultivation that involves rearing shrimp in low salinity water. This process is used to prevent disease outbreaks and improve shrimp growth. The process involves gradually decreasing the salinity of the water over several days, then maintaining the low salinity level for the duration of the cultivation period.",
		img: "/images/Homepage/Procedure/LRT.png",
		link: "",
	},
	{
		name: "Packageing",
		descripton:
			" In hatcheries, shrimp are typically packaged in plastic bags filled with water and oxygen to keep them alive during transport. The bags are then placed in insulated boxes and shipped to their destination. The packaging is designed to provide a safe and secure environment for the shrimp and prevent stress or injury during transportation",
		img: "/images/Homepage/Procedure/Packageing.jpg",
		link: "",
	},
];

const Item = ({ data, index }) => {
	return (
		<Box className="ProcessStepsItem">
			<div className="ProcessStepsItemImgDiv">
				<img src={data.img} alt={data.name} className="ProcessStepsItemImg" />
			</div>
			<h6 className="ProcessStepsItemName">
				<span className="No">{index + 1} </span>
				{data.name}
			</h6>
			<Typography
				variant="subtitle1"
				className="ProcessStepsItemText"
				sx={{
					overflow: "hidden",
					textOverflow: "ellipsis",
					display: "-webkit-box",
					WebkitLineClamp: "4",
					WebkitBoxOrient: "vertical",
					textAlign: "justify",
				}}>
				{data.text}
			</Typography>
			<Button
				component="a"
				onClick={() => (window.location.href = data.link)}
				endIcon={<ArrowRightAltSharp />}
				sx={{ textTransform: "capitalize", color: "#173C53", fontSize: "14px", fontWeight: 500 }}>
				Learn More
			</Button>
		</Box>
	);
};

const ProcessSteps = () => {
	return (
		<Stack direction="row" justifyContent="space-around" flexWrap="wrap" className="ProcessSteps">
			<h3 className="ProcessStepsHead">Procedure</h3>

			{mainProcessSteps &&
				mainProcessSteps.map((row, index) => {
					return <Item data={row} key={row.text} index={index} />;
				})}
		</Stack>
	);
};

export default ProcessSteps;
