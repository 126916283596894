import "./navbar.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

import Drawer from "@mui/material/Drawer";

import { navElements } from "assets/data";
import { Button } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';

const Navbar = () => {
	const [drawer, setDrawer] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const location = window.location.pathname;

	useEffect(() => {
		if (location !== "/") setIsScrolled(true);
	}, []);

	window.addEventListener(
		"scroll",
		() => {
			if (location === "/") {
				if (window.scrollY <= 150) setIsScrolled(false);
				else isScrolled === false && setIsScrolled(true);
			}
		},
		false
	);

	const navRight = () => {
		return (
			<div className="navRight">
				{navElements.map((row) => {
					return (
						<a
							key={row.link}
							className={`navLinkElement ${
								window.location.pathname === row.link && "navLinkElementActive"
							} `}
							href={row.link}>
							{row.name}
						</a>
					);
				})}
			</div>
		);
	};

	return (
		<Box
			component="header"
			
			className={isScrolled === true ? "Navbar navScrolled" : "Navbar"}
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			position="fixed">
			<nav className="insideNav" position="relative">
				<Link to="/" className="navLeft">
					<img src="/images/newCompanyLogo.png" alt="" className="compLogo" />
				</Link>

				<IconButton
					aria-label="delete"
					onClick={() => setDrawer(!drawer)}
					sx={{ display: { xs: "block", md: "none" }, width: "50px", height: "50px", alignSelf: "center" }}>
					<MenuIcon />
				</IconButton>
				<div className="navRightDesktop">{navRight()}</div>
			</nav>
			<Drawer anchor="right" variant="temporary" open={drawer} onClose={() => setDrawer(false)}>
				<Box height={80} />
				{navRight()}
			</Drawer>
			<Button sx={{position:"absolute",top:"120%",right:"3%",fontSize:{xs:"18px",sm:"20px"}}} variant="contained"  component="a" href="tel:949-448-7111" startIcon={<CallIcon />} >+91 9494487111</Button>
		</Box>
	);
};
 
export default Navbar;
