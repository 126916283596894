import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./eventsNews.css";

import { EventItems } from "assets/data";

const Item = (props) => {
	return (
		<Stack className="eventsNews_item1" alignItems="center" mr={3} borderRadius={2} sx={{ background: "#FFF" }}>
			<Box
				sx={{
					width: "100%",
					overflow: "hidden",
					position: "relative",
				}}
				height={{ xs: 220, sm: 300 }}>
				<img src={props.data.img} alt="Img" className="eventsNews_item1_img" />
			</Box>
			<Typography pt={2} pl={2} pb={6} textAlign="left" sx={{ width: "100%" }}>
				{props.data.text}
			</Typography>
		</Stack>
	);
};

const EventsNews = () => {
	return (
		<div className="eventsBg1">
			<Stack component="section" className="eventsNews">
				<Typography variant="h4" pb={4} fontWeight={800} sx={{ color: "#fff" }}>
					Events & News
				</Typography>
				<div className="embla">
					<div className="embla__viewport">
						<div className="embla__container">
							{EventItems &&
								EventItems.map((row) => (
									<div className="embla__slide" key={row.text}>
										<div className="embla__slide__inner">
											<Item data={row} key={row.imgUrl} />
										</div>
									</div>
								))}

							{!EventItems && <div></div>}
						</div>
						<button className="embla__button embla__button--prev " type="button"></button>
						<button className="embla__button embla__button--next" type="button"></button>
					</div>
				</div>
			</Stack>
		</div>
	);
};

export default EventsNews;
