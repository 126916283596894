import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./featuresSection.css";
import { ArrowRightAltSharp } from "@mui/icons-material";

const ItemsData = [
	{
		name: "Seed packing",
		descripton:
			"We ensure the use of premium packaging materials, prioritizing exceptional quality and maintaining high packaging standards. ",
		img: "images/HomePage/seedpack.png",
	},
	{
		name: "Larvae  and Post Larvae section",
		descripton:
			"We specialize in providing specific pathogen-free shrimp seeds, including WSSV (White Spot Syndrome Virus) and EHP (Epizootic Hepatopancreatic Microsporidium) resistant varieties. Our focus is on delivering high-quality, disease-free seeds to support the success and sustainability of shrimp farming.",
		img: "images/HomePage/larwe.png",
	},
];

const Item = ({ data }) => {
	return (
		<Box className="facilityItem">
			<div className="ProcessStepsItemImgDiv">
				<img src={data.img} alt={data.name} className="ProcessStepsItemImg" />
			</div>
			<h6 className="ProcessStepsItemName">{data.name}</h6>
			<Typography variant="subtitle1" className="ProcessStepsItemText">
				{data.descripton}
			</Typography>
		</Box>
	);
};

const FeaturesSection = () => {
	return (
		<Stack direction="row" justifyContent="center" className="featuresSection">
			<Box className="featuresLeft">
				<h6 className="featuresLeftHead">Our top facilities</h6>
				<Typography variant="subtitle1" className="featuresLeftHeadText">
					Our hatchery facility stands as a pinnacle of excellence, renowned for its top-tier operations in
					breeding and rearing shrimp seedlings. With unmatched quality and expertise, we lead the industry in
					delivering superior hatchery services and products.
				</Typography>
				<Item
					data={{
						name: "Maturation Tanks",
						img: "images/HomePage/roundtank.png",
						descripton:
							"Through the implementation of round tanks and brute stock feeding will increase, we are able to optimize the production of nauplii, leading to a significant improvement in both the quality and quantity of shrimp seedlings. This approach ultimately enhances the overall output and success of our shrimp hatchery operations.",
					}}
				/>
			</Box>
			<Box className="featuresRight">
				{ItemsData &&
					ItemsData.map((row) => {
						return <Item data={row} key={row.descripton} />;
					})}
			</Box>
		</Stack>
	);
};

export default FeaturesSection;
