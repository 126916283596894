import Stack from "@mui/material/Stack";
import "./procedure.css";
import Steps from "./steps";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { mainProcessSteps } from "assets/data";

const Item = ({ data, index }) => {
	return (
		<Stack
			direction={{ sm: "column", md: "row" }}
			justifyContent="space-around"
			alignItems={{ xs: "flex-end", md: "center" }}
			className="stepsItem2sd">
			{index % 2 === 0 && (
				<Box className="mainStepsItemLeft">
					<img src={data.img} alt="" className="mainstepsItemImg2" />
				</Box>
			)}
			<Stack className="stepsItemRight2" spacing={{ xs: 2, md: 2 }}>
				<h4 className="processItemHead1">{data.name}</h4>
				<Box className="stepsItemLeftMobile1">
					<img src={data.img} alt="" className="mainstepsItemImg2" />
				</Box>
				<Typography variant="subtitle1" display={{ xs: "none", sm: "block" }} fontSize={{ xs: 14, md: 16 }}>
					{data.text}
				</Typography>
				<Button
					component="a"
					onClick={() => (window.location.href = data.link)}
					sx={{
						width: "150px",
						background: "var(--primary)",
						"&:hover": { background: "var(--primary)" },
						color: "#fff",
					}}>
					Read More
				</Button>
			</Stack>
			{index % 2 != 0 && (
				<Box className="mainStepsItemLeft">
					<img src={data.img} alt="" className="mainstepsItemImg2" />
				</Box>
			)}
		</Stack>
	);
};

const Procedure = () => {
	return (
		<main>
			<>
				{" "}
				<div className="ProcedureTopBanner">
					<div className="transparentblock" />
					{/* <img src={pathData.img} className="ProcedureStepTopBannerRight" alt="" /> */}
					<video
						className="ProcedureTopBannerRight"
						src="/images/Procedure/procedureVideo.mp4"
						autoPlay
						loop
						muted></video>
					<div className="ProcedureTopBannerLeft">
						<h2 className="ProcedureHead">Procedure</h2>
					</div>
				</div>
			</>
			<div className="Procedure">
				<Stack className="process">
					<Steps className="steps" />

					{mainProcessSteps &&
						mainProcessSteps.map((row, index) => {
							return <Item data={row} key={row.text} index={index} />;
						})}
				</Stack>
			</div>
		</main>
	);
};

export default Procedure;
