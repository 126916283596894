import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import Navbar from "components/Navbar/Navbar";
import { useState, useEffect } from "react";

// mui Theme
import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { ThemeProvider } from "@mui/material/styles";
import Footer from "components/Footer/Footer";
import axios from "axios";
import { Box } from "@mui/material";
import PrivacyPolicy from "pages/privacypolicy";
import ProcedurePage from "pages/ProcedurePage";
import ProcedurePageSteps from "pages/ProcedurePageSteps";
import AboutUs from "components/AboutUs/AboutUs";
import EventsPage from "pages/EventsPage";

// Create a theme instance.
const theme = createTheme({
	typography: {
		fontFamily: `"DM Sans",sans-serif`,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
	button: {
		textTransform: "capitalize",
		Elevation: 0,
	},
	palette: {
		primary: {
			main: "#556cd6",
		},
		secondary: {
			main: "#19857b",
		},
		error: {
			main: red.A400,
		},
	},
});

const navHead = [{}];
function App() {
	// Axois Defaults

	axios.defaults.baseURL = "https://api.airtable.com/v0/app61erWJtPK8dU9F";
	axios.defaults.headers.common["Authorization"] = "Bearer key0K8InZ2JdeKzLR";
	axios.defaults.headers.post["Content-Type"] = "application/json";
	const navHead = [
		{
			name: "link1",
			link: "link1",
		},
		{
			name: "link2",
			link: "link2",
		},
		{
			name: "link3",
			link: "link1",
		},
		{
			name: "link3",
			link: "link3",
		},
	];

	return (
		<div className="App">
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					{navHead && <Navbar data={navHead} />}
					<Box height={{ xs: 80, md: 80 }} />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/procedure" element={<ProcedurePage />}></Route>
						<Route path="/filteration" element={<ProcedurePageSteps />} />
						<Route path="/maturation-process" element={<ProcedurePageSteps />} />
						<Route path="/LRT" element={<ProcedurePageSteps />} />
						<Route path="/packaging" element={<ProcedurePageSteps />} />
						<Route path="/privacypolicy" element={<PrivacyPolicy />} />
						<Route path="/events" element={<EventsPage />} />
						{/* <Route
							path=":subPage"
							element={navHead && <ContentPage elements={navElements} head={navHead} />}
						/> */}
					</Routes>
					{navHead && <Footer head={navHead} />}
				</ThemeProvider>
			</BrowserRouter>
		</div>
	);
}

export default App;
